@import '../../App.scss';

.merchant-list-container {
    padding: 0.5rem;
}


.back {
    @include back-button;
    // margin: 2rem;
    // margin-left: 1.5rem;
}

.merchant-list {
    display: flex;
    flex-direction: column;
}

.merchant-list-title {
    font-size: 2.2rem;
    font-weight: 700;
    margin-left: 1.5rem;
    margin-top: 2rem;

    @media screen and (max-width:670px){
        font-size: 1.5rem;
    }
}

.merchant-list-content {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    gap: .2rem;
    align-items: start;
    flex-wrap: wrap;
    // width: 75%;

    // margin-left: 2rem;
    // margin-right: 1rem;
    a {
        text-decoration: none;
        color: inherit;
    }
}

.merchant-item {
    margin-left: 1.25rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    margin-top: 2.2rem;
    margin-right: 2rem;
    width: 20rem;

    img {
        width: 100%;
    }
}

.merchant-list-profile {
    flex-wrap: wrap;
    overflow-wrap: normal;
    margin-right: 0.2rem;
}


.merchant-catalog-detail {
    display: flex;
    flex-direction: column;
    margin-right: 1.45rem;
}
// .App {
//   text-align: center;
// }

.App {
  display: flex;
  flex-direction: column;
}

.app-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  // position: fixed;
  // height: 100vh;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.app-container-main {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.cancel-btn {
  background-color: #e74c3c;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 1rem;
}

/* Style the scrollbar */
::-webkit-scrollbar {
  width: .1px;
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

::-webkit-scrollbar-thumb {
  background-color: #666;
  border-radius: 4px;
}

$themeColor: #ff80bf;

@mixin theme-button {
  margin: 1rem;
  background-color: $themeColor;
  /* Pink */
  border: none;
  color: #fff;
  /* White */
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

@mixin back-button {
  background-color: #29D4A0;
  /* White */
  color: #ffffff;
  margin: .2rem .8rem;
  /* Black */
  padding: .0rem .5rem 0.2rem 0rem;
  font-size: 1.2rem;
  border: 1px solid #f7e1e1;
  /* Black */
  border-radius: 20px;
  cursor: pointer;
  text-decoration: none;
  width: 7.2rem;

  &:before {
    content: '\2039';
    position: relative;
    left: -20%;
    font-size: 1.6rem;
  }

  // &:hover {
  //   background-color: #f5f5f5;
  //   /* Light Gray */
  // }
}

hr {
  height: 2px;
  background-color: #000;
  border: none;
}

.modal-input-container {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  input {
    border-radius: 5px;
    max-width: 50vw;
    // border: none;
    border: #666 1px solid;
    padding: 5px;

    ::placeholder {
      color: gainsboro;
    }
  }
}

.ReactModal__Overlay {
  z-index: 99;
  // position: absolute;
  // left: 0;
  // right: 0;
  // top: 0;
  // bottom: 0;
  background-color: rgba(224, 212, 212, 0.338);
}

.ReactModal__Content {
  position: absolute;
  // left: 1rem;
  // right: 1rem;
  // top: 1rem;
  // bottom: 1rem;
  background-color: #6db2d8;
  box-shadow: 0 0 10px 0 rgba(0, 0, 97, 0.5);
  overflow: auto;
  border-radius: 4px;
  outline: none;
}
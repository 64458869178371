.app-header {
    min-height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;

    // @media screen and (max-width:600px) {
    //     justify-content: start;
    // }
    // font-size: calc(8px + 2vmin);
    // background-image: url('/header-bg.png');
    // background-size: cover;
    // background-position: center;
    color: black;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    // width: 70%;
    @media screen and (max-width:600px) {
        justify-content: space-between;
        margin-bottom: 2rem;
    }
}

.app-logo {
    display: flex;
    flex: 0 0 15%;
    justify-content: start;
    align-items: center;
    padding: 1rem 0rem;
    min-width: 200px;
    font-size: 1.5rem;
    font-weight: 800;

    span{
        @media screen and (max-width:600px) {
            display: none;
        }
    }
}

// .main-title {
//     flex: 1;
//     display: flex;
//     font-size: larger;
//     font-weight: 700;
//     display: flex;
//     justify-content: start;
// }

.landing-tabs {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-right: 2rem;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;

    @media screen and (max-width:600px) {
        gap:0.75rem;
        font-size: 1rem;
        flex-direction: column;
        margin-right: .2rem;
        align-items: end;
        margin-top: 1rem;
    }

    a {
        // margin-left: .75rem;
        outline: none;

    }

    a:visited {
        color: black;
        /* Set the color for visited link */
    }

    a:focus,
    a:active {
        outline: none;
    }

    a:link {
        text-decoration: none;
    }


    a:visited {
        text-decoration: none;
    }


    a:hover {
        text-decoration: none;
    }


    a:active {
        text-decoration: none;
    }
}

.user-profile {
    // margin-right: 1.5rem;
    display: flex;
    font-size: 1.2rem;
    gap: .5rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 600;
    align-items: center;
    // margin-right: .4rem;

    img {
        border-radius: 50%;

        // @media screen and (max-width:600px) {
        //     display: none;
        // }
    }


    @media screen and (max-width:440px) {
        font-size: .9rem;
        gap: .5rem;
    }
}

.user-profile button {
    display: flex;
    // flex: 0 0 30%;
    justify-content: center;
    padding: .5rem .7rem;

    @media screen and (max-width:610px) {
        padding: .3rem;
        font-size: .7rem;
        width: 90px;
        margin-right: 0;
        margin-top: .3rem;
        // font-size: .2rem;
    }

    background-color: #e05e90;
    margin-right: 1rem;
    /* Red */
    color: #fff;
    /* White */
    // padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;

    font-weight: 700;
}
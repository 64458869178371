@import '../../App.scss';

.myubc-container {
  margin-left: 1rem;
  margin-right: 1rem;
  // width: 63vw;
  // min-width: 63vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // flex: 1 1 auto;
}

.myubc-profile {
  display: flex;
  flex-direction: column;
  // width: 68vw;
  margin-bottom: 2rem;
}

.myubc-container-title {
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 1.5rem;
  margin-top: 1rem;
}

.card {
  // width: 24rem;
  background-color: #f1f1f1;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  // margin-right: 1rem;
  display: flex;
  flex-direction: column;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.header h2 {
  margin: 0;
  color: #333;
}

.content p {
  margin: 0;
  font-size: 14px;
  color: #777;
}

.card-container {
  display: flex;
  flex-direction: column;
  max-width: 20rem;
}

.update-profile {
  @include theme-button;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  // margin-right: 1rem;
  // width: 26rem;
  max-width: 19rem;
}

.scan-qr-btn {
  @include theme-button;
  // width: 24rem;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  // margin-right: 1rem;
  max-width: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .scan-qr-icon {
    margin-right: 1rem;
  }
}

.scanner {
  width: 95%;
  height: 20rem;
}

.update-profile-modal {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: larger;
  display: flex;
  flex-direction: column;
  // width: 60vw;
  height: 500px;
}

.update-profile-modal label {
  margin-right: 1rem;
  margin-bottom: 1rem !important;
}

.update-profile-modal button {
  margin-right: 1rem;
  @include theme-button;
  margin-left: 0%;
}


.scanHistory {
  width: 280px;
}

.scanHistory-f {
  display: flex;
  justify-content: space-around;
}

.h-i-time {
  span {
    font-weight: bold;

  }
}

.h-i-name {
  span {
    font-weight: bold;
  }
}
@import '../../App.scss';

.banner {
    // background-image: url('../../header-bg.png');
    background-size: cover;
    height: 25vh;
    margin: 0.25rem;
    border-radius: 5px;

    img{
      width: 98%;
      height: 100%;
      margin-left: 0.5rem;
      margin-top: .5rem;
      border-radius: 10px;
    }
  }
  
  
  .events-container{
    background-color: #eaeef5;
    // width: 68vw;

    // @media screen and (max-width:930px) {
    //   width: 62vw;
    // }

    // @media screen and (max-width:867px) {
    //   width: 75vw;
    // }

    // @media screen and (max-width:666px) {
    //   width: 85vw;
    // }
  }

  .event-video{
    width: 55%;
    // height: 17rem;
    margin-left: 1.25rem;
    margin-bottom: .4rem;
    margin-top: 1.2rem;
    // margin-right: 1rem;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: start;
    left: 0;
    // padding-top: 3.25%;
    padding-right: 4.25%;
    // padding-top: 3.25%;

    @media screen and (max-width:650px) {
      width: 90%;
    }

  }

  .react-player {
    // position: absolute;
    top: 0;
    left: 0;
  }
  
  .events-list {
    // background-color: rgb(228, 240, 250);
    margin-left: .5rem;
    padding: 0.3rem;
    border-radius: 10px;
    margin-bottom: 10rem;
  }
  
  .event-header {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    margin: 0.5rem;
  }
  
  .event-title {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin-left: 0.4rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  
  .event-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    margin-right: .5rem;
    padding: 0.3rem;
    align-items: start;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    // justify-content: start;
  }

  .event-item p{
    // width: 100%; /* Set the desired width for the <p> tag */
    word-wrap: break-word; 
  }
  
  .event-item p {
    display: flex;
    flex-direction: row;
    justify-content: start;
    margin-left: 1.2rem;
  }
  
  .event-item img {
    max-width: 100%;
    height: auto;
    max-height: 500px;
  }


  .avatar img{
    border-radius: 50%;
    height: 70px;
    width: 70px;
  }
  
  .author-name {
    font-weight: 700;
    margin-bottom: 0.7rem;
  }

  .new-event-btn{
    margin: 1rem 1rem 1rem 1rem;
    background-color: #2b7ba9; /* Pink */
    border: none;
    color: #fff; /* White */
    padding: 10px 20px;
    font-size: 1.1rem;
    font-family: sans-serif;
    font-weight: 800;
    border-radius: 5px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);

  }

  .video{
    max-width: 90%;
    // height: 40vh;
    margin: 1rem;
  }

  .new-event-btn:hover{
    background-color: #80b3ff; /* Blue */
  }

  .new-event-btn a{
    text-decoration: none;
    color: white;
  }

  .event-image{
    // width: 45rem;
    margin: 1rem;
  }

  .event-item-title{
    font-size: large;
    margin-left: 1.2rem;
    font-weight: 800;
    margin-top: 1rem;
  }

  .create-offer-modal{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: larger;
    display: flex;
    flex-direction: column;
    width: 60vw;
    height: 500px;
}

.create-event-modal label{
    margin-right: 1rem;
    margin-bottom: 1rem !important; 
}

.create-event-modal button{
    margin-right: 1rem;
    @include theme-button;
    margin-left: 0%;
}
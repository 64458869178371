@import '../../App.scss';

.offers-title {
    font-size: larger;
    font-weight: 700;
    margin-top: 3rem;
    margin-bottom: 2rem;
    border-top: 2px solid #ebbbe4;
    margin-left: 1rem;
}

.merchant-offers-list {
    margin: 1rem;
    display: flex;
    flex-wrap: wrap;
}

.merchant-offer-item {
    margin-right: 2rem;

    img {
        // border: #fa97eb solid 2px;
        width: 16rem;
        height: 8rem;
        border-radius: 10px;
    }
}

.merchant-container {

    // padding: 1rem;
    @media screen and (max-width:850px) {
        width: 100%;
    }

    @media screen and (max-width:480px) {
        width: 100%;
    }
}

.merchant-info {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    // background-color: rgb(238, 102, 227)
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: medium;

    img {
        // height: 10rem;
        width: 100%;
        max-width: 40rem;
    }
}

.merchant-info img {
    height: 20rem;
    margin-bottom: 1rem;
    border-radius: 10px;
}

.merchant-name {
    font-weight: 800;
    font-size: larger;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}

.back {
    @include back-button;
}

.edit {
    @include theme-button;
    margin: 0;
    margin-right: 1rem;
    margin-top: 1rem;
}

.remove {
    @include theme-button;
    margin: 0;
    margin-top: 1rem;
    background-color: rgb(219, 75, 75);

}

.create-new-offer-btn {
    @include theme-button;
    margin: 0;
}

.create-offer-modal {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: larger;
    display: flex;
    flex-direction: column;
    width: 60vw;
    height: 500px;
}

.create-offer-modal label {
    margin-right: 1rem;
    margin-bottom: 1rem !important;
}

.create-offer-modal button {
    margin-right: 1rem;
    @include theme-button;
    margin-left: 0%;
}

.offer-title {
    display: flex;
    justify-content: space-between;
    align-items: center;


    button {
        @include theme-button;
        margin: 0%;
        // height: 2rem;
        padding: 4px;
        font-size: .8rem;
        background-color: rgb(97, 97, 220);
    }
}

.qr {
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.merchantScanHistory {
    width: 350px;
    margin-left: 1rem;
}

.scanHistory-f {
    display: flex;
    justify-content: space-between;
}

.h-i-time {
    span {
        font-weight: bold;

    }
}

.h-i-name {
    span {
        font-weight: bold;
    }
}
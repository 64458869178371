@import '../../App.scss';

.back {
    @include back-button;
    // margin: 2rem;
}

.confirm {
    @include theme-button;
    background-color: rgb(88, 103, 232);
}

.redeem-offer-container{
    background-color: transparent;
    padding: 1rem;

    p{
        margin: 1rem;
    }
}
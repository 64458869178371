.main-content {
  flex: 1 1 auto;
  // background-color: #e2e3fa;
  // padding: 20px;
  margin: .0rem .0rem;
  // box-sizing: border-box;
  // overflow-y: auto;
  height: 100%;
  // margin: 0.5rem;
  // border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y:auto;
  // width: 71vw;
  // background-color: #eaeef5;
  // max-width: 82%;
  // margin-left: 1rem;
  // margin-right: 1rem;

  @media screen and (max-width:1275px) {
    // width: 70vw;
  }

  @media screen and (max-width:980px) {
    // width: 60vw;
  }

  @media screen and (max-width:900px) {
    // width: 76vw;
  }

  @media screen and (max-width:722px) {
    // width: 88vw;
  }

  @media screen and (max-width:455px) {
    // width: 90vw;
  }
  // position: relative;
}
@import '../../App.scss';

.left-sidebar {
  // position: fixed;
  // top: 0;
  // left: 0;
  flex:0 0 auto;
  height: 50vh;
  width: 155px;
  background-color: #f8f8f8;
  overflow-y: auto;
  overflow-x: hidden;
  transition: width 0.2s ease-in-out;
  // border-top-right-radius: 10px;
  // border-top: 2px solid #ebbbe4;
  // border-right: 1px solid #ebbbe4;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 600;

  @media screen and (max-width:900px) {
    width: 155px;
  }

  @media screen and (max-width:700px) {
    width: 55px;
  }
}

.left-sidebar a {
  color: black; /* Set the desired color for the link */
  text-decoration: none;
}

a:visited {
  color: black; /* Set the color for visited link */
}

a:focus,
a:active {
  outline: none;
}

.left-sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 2px solid #ccc;
  align-items: center;

  @media screen and (min-width:722px) {
    display: none;
  }

}

.left-sidebar-header img {
  border-radius: 50%;
  margin-left: .2rem;

  @media screen and (max-width:670px) {
    display: none;
  }
}

// .left-sidebar-header:first-child{
//   margin-right: 0.4rem;
// }

.left-sidebar-header-name {
  margin: 0;
  font-size: 1rem;
  margin-left: .5rem;
  font-weight: 800;

  // @media screen and (max-width:670px) {
  //   display: none;
  // }
}

.left-sidebar-header button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.left-sidebar.collapsed {
  width: 40px;

  .left-sidebar-header {
    padding: 0px;
  }
}

.left-sidebar.expanded {
  width: 15%;
}

.left-sidebar.collapsed .left-sidebar-header h2 {
  display: none;
  visibility: hidden;
}

.left-sidebar.collapsed .left-sidebar-header button {
  display: block;
}

.left-sidebar-content {
  padding: 10px;
}

.left-sidebar.collapsed .option-list {
  display: none;
}

.option-list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: .85rem;
  span {
    @media screen and (max-width:670px) {
      display: none;
    }
  }
}

.option-list li {
  // padding: 10px;
  // border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  transition: background-color 0.2s ease-out;
}

.option-list li:last-child {
  border-bottom: none;
}

.option-list li:hover {
  background-color: $themeColor;
  cursor: pointer;
}

.seletedTab {
  background-color: $themeColor;
  color: white;
}

.option-list li span {
  margin-left: 0.5rem;
}

/* Style the scrollbar */
::-webkit-scrollbar {
  width: 1px;
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

::-webkit-scrollbar-thumb {
  background-color: #666;
  border-radius: 4px;
}
@import '../../App.scss';

.login {
    height: 100vh;
    width: 92vw;
    display: flex;
    align-items: center;
    margin-top: 3rem;
    justify-content: start;
    flex-direction: column;
  }
  .login__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    // background-color: #dcdcdc;
    padding: .5rem;
  }
  .login__textBox {
    padding: 10px;
    font-size: 1.4rem;
    margin-bottom: 10px;
    border: none;
    border-radius: 2%;
    border-bottom: grey 1.1px solid;

    *:focus {
      outline:none;
  }
    // background-color: #eff2f7;
  }
  .login__btn {
    padding: 10px;
    font-size: 1.5rem;
    margin-bottom: 10px;
    margin-top: 1rem;
    border: none;
    color: white;
    background-color: rgb(69, 139, 225);
    font-weight: 700;
  }
  .login__google {
    background-color: white;
    color: rgb(105, 102, 102);
    border: gray 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .google-icon{
     width: 1.8rem;
     margin-right: 1rem;
  }
  .login div {
    margin-top: 7px;
  }
@import '../../App.scss';

.merchant-catalog-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin-left: .1rem;
}

.merchant-my-list-content {
    display: flex;
    flex-wrap: wrap;
    margin-left: 1rem;

    a {
        color: inherit;
        text-decoration: none;
    }

    // img {
    //     width: 15rem;
    //     height: 8rem;
    //     border-radius: 10px;

    //     @media screen and (max-width:850px) {
    //         width: 15rem;
    //         height: 8rem;
    //     }

    //     @media screen and (max-width:480px) {
    //         width: 15rem;
    //         height: 8rem;
    //     }
    // }
}

.merchant-catalog-list-title {
    display: flex;
    justify-content: start;
    margin-bottom: 1rem;
    margin-left: .5rem;
    // margin-top: 1rem;
    // gap: .1rem;
    // max-width: 80%;
}

.merchant-catalog-list {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    flex-wrap: wrap;
    // margin-left: 1rem;


    @media screen and (max-width:480px) {
        width: 20rem;
        // height: 8rem;
        font-size: .75rem;
    }

    img {
        width: 15rem;
        height: 8rem;
        border-radius: 10px;

        @media screen and (max-width:850px) {
            width: 100%;
            height: 8rem;
        }

        @media screen and (max-width:480px) {
            width: 100%;
            height: 8rem;
        }
    }
}

.merchant-catalog-group {
    display: flex;
    flex-direction: column;
    // margin-right: 1.45rem;
    align-items: center;
    margin-bottom: 1.5rem;
    // justify-content: start;
    // align-items: start;

    @media screen and (max-width:850px) {
        width: 50%;
        // font-size: 0.4rem;
        margin: 1.4rem 0rem;
    }

    @media screen and (max-width:480px) {
        width: 50%;
        // font-size: 0.1rem;
        margin: 1.4rem 0rem;
    }
}

.merchant-item-my {
    margin-right: 3em;
    width: 12rem;

    img {
        width: 100%;
    }
}

.merchants-banner {
    width: 66vw;
    height: 25vh;
    // margin-left: 1rem;
    border-radius: 10px;

    @media screen and (max-width: 690px) {
        width: 85vw;
    }

}

.register-merchant {
    @include theme-button;
    margin-left: 1.2rem;
    margin-bottom: 2rem;
    margin-top: 0;
    border-radius: 10px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: large;
    font-weight: 800;
    padding: 7px 10px;

    @media screen and (max-width:780px) {
        font-size: 1rem;
    }

    // margin-left: 0;
}

.tab {
    padding: 10px 10px 10px 10px;
    // background-color: #ccc;
    cursor: pointer;
    color: black;
    font-size: larger;
    font-weight: 800;

    @media screen and (max-width:780px) {
        font-size: 1rem;
    }
}

.active-tab {
    // background-color: rgb(238, 106, 198);
    font-size: larger;
    font-weight: 900;
    text-decoration: underline;
    // text-decoration-thickness: 0.2em;
    text-decoration-color: $themeColor;
    color: $themeColor;

    @media screen and (max-width:780px) {
        font-size: 1rem;
    }

    /* Adjust this value to increase the thickness */
    // margin-bottom: 0.5em;
    /* Adjust this value to increase the gap */
    /* Change this to the desired highlight color */
}

.create-merchant-modal {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: larger;
    display: flex;
    flex-direction: column;
    width: 60vw;
    height: 500px;
}

.create-merchant-modal label {
    margin-right: 1rem;
    margin-bottom: 1rem !important;
}

.create-merchant-modal button {
    margin-right: 1rem;
    @include theme-button;
    margin-left: 0%;
}

.create-merchant-modal label input {
    margin-left: .5rem;
}
@import '../../App.scss';

.landing-container {
    display: flex;
    flex-direction: column;
    margin: .5rem;
    justify-content: start;
    overflow-y: scroll;
    height: 100%;
}

.all-merchants-btn {
    display: flex;
    justify-content: center;

    // width: 10rem;
    button {
        @include theme-button;
        padding: 1rem;
        font-size: 1.5rem;
        background-color: rgb(32, 93, 150);
        border-radius: 15px;
    }
}

.landing-title {
    margin-top: 3rem;
    margin-bottom: 6rem;
    display: flex;

    @media screen and (max-width:740px) {
        flex-direction: column;
    }

    img {
        border-radius: 5%;
        margin-right: 1rem;

        width: 20rem;
        height: 17rem;

        @media screen and (max-width:1100px) {
            width: 18rem;
            height: 17rem;
        }

        @media screen and (max-width:740px) {
            width: 20rem;
            height: 17rem;
            margin-left: 2rem;
            margin-top: 2rem;
        }
    }
}

.landing-title-text {
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
}

.landing-title-text-main {
    margin-top: 2rem;
    margin-left: 2rem;
    font-size: 3rem;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;

    @media screen and (max-width:600px) {
        font-size: 1.1rem;
    }
}

.landing-title-text-sub {
    margin-top: 1rem;
    margin-left: 2rem;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 1.5rem;

    @media screen and (max-width:600px) {
        font-size: 0.9rem;
    }
}

.landing-register-btn-group {
    display: flex;
    margin-left: 2rem;
}

.landing-register-btn {
    margin-right: 1rem;
    @include theme-button;
    margin-left: 0%;
    font-weight: 700;
    font-size: 0.8rem;
    width: 14rem;
    height: 3rem;

}


.landing-register-merchant-btn {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.merchant-brands {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        font-size: 1.2rem;
    }

}

.brand-imgs {
    background-color: white;
    display: flex;
    justify-content: center;
    gap: 3rem;
    flex-wrap: wrap;

    img {
        width: 20rem;
        height: 15rem;

        @media screen and (max-width:1000px) {
            width: 32vw;
            height: 8vh;
        }
    }
}
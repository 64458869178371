.register {
    height: 100vh;
    width: 92vw;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
  }
  .register__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    // background-color: #dcdcdc;
    padding: .7rem;
  }
  .register__textBox {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    border: none;
    border-radius: 2%;
    border-bottom: grey 1.1px solid;
  }
  .register__btn {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    border: none;
    color: white;
    background-color: black;
    margin-top: 1rem;
    background-color: rgb(69, 139, 225);
    font-weight: 700;
  }
  .register__google {
    background-color: white;
    color: rgb(105, 102, 102);
    border: gray 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .register div {
    margin-top: 7px;
  }
.right-bar {
  flex: 0 0 20%;
  background-color: #fcf8f8;
  // padding: 20px;
  // box-sizing: border-box;
  // overflow-y: auto;
  // height: 100%;
  // position: relative;
  // border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  // margin-right: .3rem;
  right: 0;
  // position: absolute;
  // border-left: #fa97eb solid 1px;
}

.latest-offers-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  // justify-content: center;
}

.lastest-offer-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: 500;
  font-size: .8rem;
}


.lastest-offer-item img {
  width: 260px;
  height: 180px;
  margin-bottom: .95rem;
  margin-left: .5rem;
  border-radius: 10px;
  // border: #fa97eb solid .5px;

}

.latest-offers-list:last-child {
  margin-bottom: 10rem;
}

@media screen and (max-width:900px) {
  .right-bar {
    display: none;
  }
}

@media screen and (max-width:970px) {
  .lastest-offer-item img {
    width: 120px;
    height: 90px;
  }
}

@media screen and (max-width:1440px) {
  .lastest-offer-item img {
    width: 200px;
    height: 150px;
  }
}

@media screen and (max-width:1155px) {
  .lastest-offer-item img {
    width: 140px;
    height: 100px;
  }
}
@import '../../App.scss';

.offers-list {
    // margin: 1rem;
    margin-top: .5rem;
    display: flex;
    flex-wrap: wrap;
    // gap: .2rem;
    // margin-left: 1.25rem;
    // min-width: 40vw;
    // margin-right: 5rem;

    a {
        text-decoration: none;
    }
}

.offers-all-title {
    margin: 1rem 1.25rem;
    font-size: 1.5rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    // border-bottom: $themeColor solid 1px;
    font-weight: 700;
    // text-decoration-thickness: 0.2em;
    text-decoration-color: $themeColor;
    color: $themeColor;
    font-size: larger;
    font-weight: 900;
    text-decoration: none;

    @media screen and (max-width:780px) {
        font-size: 1rem;
    }
}

.offer-item {
    // margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        font-size: 1rem;
        margin-top: .5rem;
        font-weight: 700;
    }

    // .merchant-item {
    //     display: flex;
    //     align-items: center;
    // }

    .offers-merchant-item-merchant-name {
        margin-bottom: .1rem;
    }

    img {
        width: 16rem;
        height: 8rem;
        margin-bottom: 1rem;
        border-radius: 10px;

        @media screen and (max-width:990px) {
            width: 16rem;
            height: 8rem;
        }

        @media screen and (max-width:670px) {
            width: 16rem;
            height: 8rem;
        }

        @media screen and (max-width:460px) {
            width: 16rem;
            height: 8rem;
        }


    }
}

.offers-container {
    display: flex;
    flex-direction: column;
    padding: 0rem;
}

.offers-banner img {
    height: 20vh;
    // width: 53vw;
    margin-left: 1rem;
    margin-top: 1.5rem;
    // margin-right: 2rem;

    @media screen and (max-width:670px) {
        // width: 78vw;
    }
}